import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/Register.css';
import Swal from 'sweetalert2';
import { FaUser, FaEnvelope, FaLock, FaPhone, FaCode } from 'react-icons/fa';
import { endpoints } from '../apiConfig';
import Loader from '../components/Loader';

function Register() {
  const [sponsor, setSponsor] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [agreeTnC, setAgreeTnC] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate('/dashboard');
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sponsorCode = params.get('referral');
    if (sponsorCode) {
      setSponsor(sponsorCode);
    }
  }, [location.search]);

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!agreeTnC) {
      await Swal.fire({
        title: 'Error!',
        text: 'You must agree to the terms and conditions.',
        icon: 'error',
        confirmButtonText: 'Okay'
      });
      return;
    }

    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const registrationData = {
        uid: user.uid,
        name: name,
        email: email,
        phone: phone,
        countryCode: countryCode,
        sponsor: sponsor,
      };

      const response = await axios.post(`${endpoints.register}`, registrationData);
      if (response.data.message === 'User registered successfully') {
        await sendEmailVerification(user);

        await Swal.fire({
          title: 'Success!',
          text: 'Successfully registered! A verification email has been sent to your email address.',
          icon: 'success',
          confirmButtonText: 'Okay'
        });
        navigate('/login');
      } else {
        await Swal.fire({
          title: 'Error!',
          text: 'Registration failed: ' + response.data.message,
          icon: 'error',
          confirmButtonText: 'Okay'
        });
      }
    } catch (error) {
      console.error('Error registering:', error.response ? error.response.data : error.message);
      await Swal.fire({
        title: 'Error!',
        text: 'An error occurred: ' + (error.response ? error.response.data.message : error.message),
        icon: 'error',
        confirmButtonText: 'Okay'
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="gradient-bg d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="container">
        <div className="mb-4 mt-4 text-center">
          <img 
            src={process.env.PUBLIC_URL + '/click.png'}
            alt="Logo" 
            style={{ width: '100px', marginBottom: '10px' }} 
          />
          <h2 className="text-white">Create an account</h2>
        </div>
        
        <form onSubmit={handleRegister} className="p-4 rounded shadow" style={{ background: '#444' }}>
          <div className="mb-3">
            <label className="form-label text-white">Referral Code</label>
            <div className="input-group">
              <span className="input-group-text" style={{ background: '#333', color: 'white', border: 'none' }}>
                <FaCode />
              </span>
              <input
                type="text"
                className="form-control custom-placeholder"
                placeholder="Enter Code"
                value={sponsor}
                onChange={(e) => setSponsor(e.target.value)}
                required
                style={{ background: '#333', color: 'white', borderColor: '#333' }}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label text-white">Full Name</label>
            <div className="input-group">
              <span className="input-group-text" style={{ background: '#333', color: 'white', border: 'none' }}>
                <FaUser />
              </span>
              <input
                type="text"
                className="form-control custom-placeholder"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                style={{ background: '#333', color: 'white', borderColor: '#333' }}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label text-white">Email</label>
            <div className="input-group">
              <span className="input-group-text" style={{ background: '#333', color: 'white', border: 'none' }}>
                <FaEnvelope />
              </span>
              <input
                type="email"
                className="form-control custom-placeholder"
                placeholder="your-email@xyz.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ background: '#333', color: 'white', borderColor: '#333' }}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label text-white">Phone Number</label>
            <div className="input-group">
              <span className="input-group-text" style={{ background: '#333', color: 'white', border: 'none' }}>
                <FaPhone />
              </span>
              <select
                className="form-select custom-placeholder"
                value={countryCode}
                style={{ background: '#333', color: 'white', borderColor: '#333' }}
                onChange={(e) => setCountryCode(e.target.value)}
              >
                <option value="+1">+1 (USA)</option>
                {/* Add other country codes as needed */}
              </select>
              <input
                type="text"
                className="form-control custom-placeholder"
                placeholder="Enter Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                style={{ background: '#333', color: 'white', borderColor: '#333' }}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label text-white">Password</label>
            <div className="input-group">
              <span className="input-group-text" style={{ background: '#333', color: 'white', border: 'none' }}>
                <FaLock />
              </span>
              <input
                type="password"
                className="form-control custom-placeholder"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ background: '#333', color: 'white', borderColor: '#333' }}
              />
            </div>
          </div>

          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="tnc"
              checked={agreeTnC}
              onChange={(e) => setAgreeTnC(e.target.checked)}
            />
            <label className="form-check-label text-white" htmlFor="tnc">
              I agree to the Terms and Conditions
            </label>
          </div>

          <button type="submit" className="btn btn-primary w-100">
            Register
          </button>

          <div className="text-center mt-4" style={{ marginBottom: '40px' }}>
            <a href="/forgot-password" className="text-white me-3">Forgot Password?</a>
            <span className="text-white">|</span>
            <a href="/login" className="text-white ms-3">Login</a>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
