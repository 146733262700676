// // //apiConfig.js
// const API_BASE_URL = 'http://localhost:5001';
// const API_BASE_URL_FRONTEND = 'http://localhost:3000';

// export const endpoints = {
//   io: `${API_BASE_URL}`, 
//   login: `${API_BASE_URL}/auth/login`,
//   register: `${API_BASE_URL}/api/auth/register`,
//   getUserData: `${API_BASE_URL}/api/auth/user`,
//   updateUserData: `${API_BASE_URL}/api/auth/updateuserdata`,
//   getTokens: `${API_BASE_URL}/api/auth/gettokens`,
//   referralCount: `${API_BASE_URL}/api/auth/referralcount`,
//   referrals: `${API_BASE_URL}/api/auth/referrals`,
//   getTasks: `${API_BASE_URL}/api/auth/gettasks`,
 
//   refercode: `${API_BASE_URL_FRONTEND}/register?referral=`,
//   // Add more endpoints here
// };



const API_BASE_URL = 'https://parahash.com:5001';
const API_BASE_URL_FRONTEND = 'https://parahash.com:3000';

export const endpoints = {
  io: `${API_BASE_URL}`, 
  login: `${API_BASE_URL}/auth/login`,
  register: `${API_BASE_URL}/api/auth/register`,
  getUserData: `${API_BASE_URL}/api/auth/user`,
  updateUserData: `${API_BASE_URL}/api/auth/updateuserdata`,
  getTokens: `${API_BASE_URL}/api/auth/gettokens`,
  referralCount: `${API_BASE_URL}/api/auth/referralcount`,
  referrals: `${API_BASE_URL}/api/auth/referrals`,
  getTasks: `${API_BASE_URL}/api/auth/gettasks`,
  refercode: `${API_BASE_URL_FRONTEND}/register?referral=`,
  // Add more endpoints here
};